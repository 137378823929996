<template>
  <div class="container">
    <a-layout>
      <Header />
      <a-layout>
        <Sider />
        <a-layout class="section">
          <a-layout-content :style="{
              margin: '24px 16px',
              padding: '24px',
              marginTop: '24px',
              background: '#fff',
              minHeight: '280px',
            }">
            <div class="container">
              <div ref="box">
                <div ref="header">
                  <a-page-header style="border-bottom: 1px solid rgb(235, 237, 240)" title="淘宝营销">
                    <template slot="extra">
                      <a-button class="editable-add-btn" @click="handleAdd" size="small">
                        新增
                      </a-button>
                      <a-button class="editable-add-btn" @click="exportIn" size="small">
                        导入
                      </a-button>
                    </template>
                  </a-page-header>
                </div>
                <div ref="form">
                  <a-form :form="form" layout="inline" @submit="handleSubmit" style="padding: 15px 0">
                    <a-form-item label="淘宝PID">
                      <a-input v-decorator="[
                          'pid',
                          { rules: [{ required: false }] },
                        ]" />
                    </a-form-item>
                    <a-form-item>
                      <a-button type="primary" html-type="submit" @click="getExport(0)">
                        搜索
                      </a-button>
                    </a-form-item>
                  </a-form>
                </div>
                <a-table :columns="columns" :data-source="tbMarketingList" :pagination="false"
                  :scroll="{ x: 1000, y: table_h }" :row-key="(record) => record.id" size="middle" ref="tableList">
                  <span slot="btns" slot-scope="btns, num">
                    <a-button class="editable-add-btn" v-for="btn in btns" :key="btn" @click="handel(btn, num)"
                      size="small" style="margin: 5px">
                      {{ btn }}
                    </a-button>
                  </span>
                </a-table>
                <div ref="pagination">
                  <a-pagination v-if="pageshow" :default-current="currentPage" :defaultPageSize="defaultPageSize"
                    :page-size-options="pageSizeOptions" show-size-changer show-quick-jumper :total="total_num"
                    :show-total="(total) => `共 ${total} 条`" @showSizeChange="onShowSizeChange" @change="onPageChange">
                    <template slot="buildOptionText" slot-scope="props">
                      <span>{{ props.value }}条/页</span>
                    </template>
                  </a-pagination>
                </div>
                <!-- <div ref="pagination">
                  <a-pagination v-if="pageshow" :default-current="currentPage" :defaultPageSize="defaultPageSize"
                    :page-size-options="pageSizeOptions" show-size-changer show-quick-jumper :total="total_num"
                    @showSizeChange="onShowSizeChange" @change="onPageChange" :item-render="itemRender">
                    <template slot="buildOptionText" slot-scope="props">
                      <span>{{ props.value }}条/页</span>
                    </template>
                  </a-pagination>
                </div> -->
                <a-modal v-model="visible" :title="modelTitle" :confirm-loading="confirmLoading" @ok="handleOk"
                  ok-text="确认" cancel-text="取消">
                  <a-form :form="addform" @submit="handleSubmit">
                    <a-form-item label="淘宝PID:">
                      <a-input v-decorator="[
                          'pid',
                          {
                            rules: [
                              { required: true, message: '请输入淘宝PID!' },
                            ],
                          },
                        ]" />
                    </a-form-item>
                    <a-form-item label="红包领取量：">
                      <a-input-number style="width:100%" v-decorator="[
                          'num',
                          {
                            rules: [
                              { required: true, message: '请输入红包领取量!' },
                            ],
                          },
                        ]" />
                    </a-form-item>
                  </a-form>
                </a-modal>

                <a-modal v-model="visibleIn" title="导入文件" @ok="handleInOk" ok-text="确认" cancel-text="取消">
                  <!-- <a-form :form="exportform"> -->
                  <!-- <a-form-item label="文件：" extra="最多支持1个"> -->
                  文件：
                  <a-upload name="file" list-type="picture" class="upload-list-inline" :fileList="fileList"
                    :customRequest="changeImg" @change="getchangeImg" :remove="removeImg"
                    :beforeUpload="handleBeforeUpload">
                    <a-button :disabled="!uploadBtn">
                      <a-icon type="upload" /> 上传文件
                    </a-button>
                  </a-upload>
                  <!-- </a-form-item> -->
                  <div style="padding-top:30px">
                    <a href="https://fanhongguan.oss-cn-beijing.aliyuncs.com/excel/tbRedpacketGiveData.xlsx">导入文件模板</a>
                  </div>
                  <!-- </a-form> -->
                </a-modal>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
  import Header from "@/components/Header.vue";
  import Sider from "@/components/Sider.vue";
  export default {
    name: "Car",
    data() {
      return {
        form: this.$form.createForm(this, { name: "deviceSearch" }),
        addform: this.$form.createForm(this, { name: "addform" }),
        exportform: this.$form.createForm(this, { name: "exportform" }),
        export: 0,
        columns: [
          {
            title: "淘宝PID",
            key: "pid",
            dataIndex: "pid",
            align: "center",
          },
          {
            title: "红包领取量",
            key: "num",
            dataIndex: "num",
            align: "center",
          },
          {
            title: "操作",
            key: "btns",
            dataIndex: "btns",
            scopedSlots: { customRender: "btns" },
            align: "center",
          },
        ],
        visibleIn: false,
        visible: false,
        modelTitle: "",
        confirmLoading: false,
        fileList: [],
        uploadBtn: true,
        pageshow: false,
        currentPage: 1,
        total_num: 0,
        defaultPageSize: 20,
        pageSizeOptions: ["10", "20", "30", "50", "100"],
        table_h: "",
        tbMarketingList: [],
        status: "",
        id: ""
      };
    },
    components: {
      Header,
      Sider,
    },
    mounted() {
      window.addEventListener("resize", () => this.changeTableH(), true);
      this.getSearchList({});
    },
    methods: {
      changeTableH() {
        this.table_h =
          this.$refs.box.offsetHeight -
          this.$refs.header.offsetHeight -
          this.$refs.form.offsetHeight -
          this.$refs.pagination.offsetHeight -
          60;
        if (this.table_h <= 250) {
          this.table_h = 400;
        }
      },
      handleAdd() {
        var that = this
        that.visible = true;
        that.modelTitle = "新增淘宝营销红包";
        that.confirmLoading = false;
        this.id = "";
        that.$nextTick(() => {
          that.addform.setFieldsValue({
            pid: "",
            num: "",
          });
        });
      },
      //新增、编辑提交表单
      handleOk() {
        var that = this;
        that.confirmLoading = true;
        that.addform.validateFields((error, values) => {
          if (!error) {
            var data = values;
            if (!that.id) {
              that.axios
                .post("/admin/tbRedpacketGiveData/save", data)
                .then((res) => {
                  that.confirmLoading = false;
                  //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                  if (that.$code(res)) {
                    that.form.validateFields((error, values) => {
                      that.getSearchList(values);
                    });
                  }
                  that.visible = false;
                });
            } else {
              that.axios
                .post("/admin/tbRedpacketGiveData/modify/" + that.id, data)
                .then((res) => {
                  that.confirmLoading = false;
                  //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                  if (that.$code(res)) {
                    that.form.validateFields((error, values) => {
                      that.getSearchList(values);
                    });
                  }
                  that.visible = false;
                });
            }
          } else {
            that.confirmLoading = false;
          }
        });
      },
      //获取改变后的页码
      onPageChange(pageNumber) {
        var that = this;
        this.currentPage = pageNumber;
        that.form.validateFields((error, values) => {
          that.getSearchList(values);
        });
      },
      itemRender(current, type, originalElement) {
        if (type === "prev") {
          return <a>上一页</a>;
        } else if (type === "next") {
          return <a>下一页</a>;
        }
        return originalElement;
      },
      //获取每页条数
      onShowSizeChange(current, pageSize) {
        var that = this;
        this.defaultPageSize = pageSize;
        if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
          that.currentPage = 1;
        }
        that.form.validateFields((error, values) => {
          that.getSearchList(values);
        });
      },
      //获取列表
      getSearchList(data) {
        var that = this;
        data.limit = that.defaultPageSize;
        data.page = that.currentPage;
        // data.startTime = that.startTime;
        // data.endTime = that.endTime;
        that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
        that.pageshow = false;
        that.axios
          .get("/admin/tbRedpacketGiveData/list", {
            params: data,
          })
          .then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
              var tbMarketingList = res.data.data;
              for (var i = 0; i < tbMarketingList.length; i++) {
                tbMarketingList[i].btns = ["编辑", "删除"];
              }
              that.changeTableH();
              that.tbMarketingList = tbMarketingList;
              that.total_num = res.data.count;
              that.currentPage = res.data.currentPage;
            }
          });

        that.$nextTick(() => {
          that.pageshow = true;
        });
      },
      // 操作
      handel(btn, num) {
        var that = this;
        that.id = num.id;
        if (btn == "编辑") {
          that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
          that.axios
            .get("admin/tbRedpacketGiveData/one/" + that.id)
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.visible = true;
                that.modelTitle = "编辑淘宝营销红包";
                that.$nextTick(() => {
                  that.addform.setFieldsValue({
                    pid: res.data.data.pid,
                    num: res.data.data.num,
                  });
                });
              }
            });
        }
        if (btn == "删除") {
          that.$confirm({
            title: "提示",
            content: "是否" + btn + "此营销红包",
            okText: "确认",
            cancelText: "取消",
            onOk() {
              that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
              that.axios
                .post("/admin/tbRedpacketGiveData/del/" + that.id)
                .then((res) => {
                  that.$message.loading({
                    content: "加载中..",
                    key: "load",
                    duration: 0.00001,
                  });
                  if (that.$code(res)) {
                    that.$message.success(btn + "成功");
                    that.form.validateFields((error, values) => {
                      that.getSearchList(values);
                    });
                  }
                });
            },
          });
        }
      },
      //导入
      exportIn() {
        this.visibleIn = true;
        this.fileList = [];
      },
      handleInOk() {
        this.visibleIn = false;
        this.fileList = [];
      },
      //上传图片
      changeImg(info) {
        var that = this;
        const formData = new FormData();
        formData.append("file", info.file);
        that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
        that.axios.post("/admin/tbRedpacketGiveData/import", formData).then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          this.uploadBtn = true;
          if (that.$code(res)) {
            this.visibleIn = false;
            that.$message.success("上传成功");
            that.getSearchList({});
          }
        });
      },
      //删除图片
      removeImg() {
        this.fileList = [];
      },
      //上传前校验
      handleBeforeUpload(file) {
        console.log(file)
        if (file.type.indexOf("sheet") < 0) {
          this.$message.error("仅支持上传模板类型文件")
          this.canUpload = false
          return false;
        } else {
          this.canUpload = true
          return true
        }
      },
      getchangeImg(info) {
        if (!this.canUpload) {
          info.fileList = []
        }
        if (info.fileList.length >= 1) {
          this.uploadBtn = false;
        } else {
          this.uploadBtn = true;
        }
      },
      //筛选 导出设备
      getExport(e) {
        this.export = e;
      },
      handleSubmit(e) {
        if (e) {
          e.preventDefault();
        }
        var that = this;
        that.form.validateFields((error, values) => {
          if (that.export == 0) {
            that.currentPage = 1;
            // this.$refs.pagination.internalCurrentPage = this.nowpage;
            that.getSearchList(values);
          } else {
            var data = "";
            for (var key in values) {
              if (values[key]) {
                data += "&" + key + "=" + values[key];
              }
            }
            var myObj = {
              method: "get",
              url: "/" + that.pageNowUrl + "?export=1" + data,
              fileName: "设备列表.csv",
            };
            this.$exportMethod(myObj);
          }
        });
      },
    },
  };
</script>
<style scoped>
  table img {
    width: 100px;
  }

  .ant-table-wrapper {
    flex: 1;
  }

  .goodsInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 250px;
  }

  .goodsInfo img {
    width: 80px;
    height: 80px;
  }

  .goodsInfo>div {
    width: 165px;
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 80px;
  }

  .goodsInfo>div>div {
    width: 165px;
    text-align: left;
  }

  /* .goodsInfo>div>div>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
} */
  .goodsInfo>div>div span {
    color: #1890ff;
  }

  .goodsInfo p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }

  .orderTime {
    text-align: left;
  }

  /* /deep/.ant-pagination .ant-pagination-item {
    display: none;
  } */
</style>