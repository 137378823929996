var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-layout',[_c('Header'),_c('a-layout',[_c('Sider'),_c('a-layout',{staticClass:"section"},[_c('a-layout-content',{style:({
            margin: '24px 16px',
            padding: '24px',
            marginTop: '24px',
            background: '#fff',
            minHeight: '280px',
          })},[_c('div',{staticClass:"container"},[_c('div',{ref:"box"},[_c('div',{ref:"header"},[_c('a-page-header',{staticStyle:{"border-bottom":"1px solid rgb(235, 237, 240)"},attrs:{"title":"淘宝营销"}},[_c('template',{slot:"extra"},[_c('a-button',{staticClass:"editable-add-btn",attrs:{"size":"small"},on:{"click":_vm.handleAdd}},[_vm._v(" 新增 ")]),_c('a-button',{staticClass:"editable-add-btn",attrs:{"size":"small"},on:{"click":_vm.exportIn}},[_vm._v(" 导入 ")])],1)],2)],1),_c('div',{ref:"form"},[_c('a-form',{staticStyle:{"padding":"15px 0"},attrs:{"form":_vm.form,"layout":"inline"},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"淘宝PID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'pid',
                        { rules: [{ required: false }] } ]),expression:"[\n                        'pid',\n                        { rules: [{ required: false }] },\n                      ]"}]})],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":function($event){return _vm.getExport(0)}}},[_vm._v(" 搜索 ")])],1)],1)],1),_c('a-table',{ref:"tableList",attrs:{"columns":_vm.columns,"data-source":_vm.tbMarketingList,"pagination":false,"scroll":{ x: 1000, y: _vm.table_h },"row-key":function (record) { return record.id; },"size":"middle"},scopedSlots:_vm._u([{key:"btns",fn:function(btns, num){return _c('span',{},_vm._l((btns),function(btn){return _c('a-button',{key:btn,staticClass:"editable-add-btn",staticStyle:{"margin":"5px"},attrs:{"size":"small"},on:{"click":function($event){return _vm.handel(btn, num)}}},[_vm._v(" "+_vm._s(btn)+" ")])}),1)}}])}),_c('div',{ref:"pagination"},[(_vm.pageshow)?_c('a-pagination',{attrs:{"default-current":_vm.currentPage,"defaultPageSize":_vm.defaultPageSize,"page-size-options":_vm.pageSizeOptions,"show-size-changer":"","show-quick-jumper":"","total":_vm.total_num,"show-total":function (total) { return ("共 " + total + " 条"); }},on:{"showSizeChange":_vm.onShowSizeChange,"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"buildOptionText",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.value)+"条/页")])]}}],null,false,198454938)}):_vm._e()],1),_c('a-modal',{attrs:{"title":_vm.modelTitle,"confirm-loading":_vm.confirmLoading,"ok-text":"确认","cancel-text":"取消"},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{attrs:{"form":_vm.addform},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"淘宝PID:"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'pid',
                        {
                          rules: [
                            { required: true, message: '请输入淘宝PID!' } ],
                        } ]),expression:"[\n                        'pid',\n                        {\n                          rules: [\n                            { required: true, message: '请输入淘宝PID!' },\n                          ],\n                        },\n                      ]"}]})],1),_c('a-form-item',{attrs:{"label":"红包领取量："}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'num',
                        {
                          rules: [
                            { required: true, message: '请输入红包领取量!' } ],
                        } ]),expression:"[\n                        'num',\n                        {\n                          rules: [\n                            { required: true, message: '请输入红包领取量!' },\n                          ],\n                        },\n                      ]"}],staticStyle:{"width":"100%"}})],1)],1)],1),_c('a-modal',{attrs:{"title":"导入文件","ok-text":"确认","cancel-text":"取消"},on:{"ok":_vm.handleInOk},model:{value:(_vm.visibleIn),callback:function ($$v) {_vm.visibleIn=$$v},expression:"visibleIn"}},[_vm._v(" 文件： "),_c('a-upload',{staticClass:"upload-list-inline",attrs:{"name":"file","list-type":"picture","fileList":_vm.fileList,"customRequest":_vm.changeImg,"remove":_vm.removeImg,"beforeUpload":_vm.handleBeforeUpload},on:{"change":_vm.getchangeImg}},[_c('a-button',{attrs:{"disabled":!_vm.uploadBtn}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传文件 ")],1)],1),_c('div',{staticStyle:{"padding-top":"30px"}},[_c('a',{attrs:{"href":"https://fanhongguan.oss-cn-beijing.aliyuncs.com/excel/tbRedpacketGiveData.xlsx"}},[_vm._v("导入文件模板")])])],1)],1)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }